























































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import { getComponent, getView  } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import DataLoading from '@/lib/mixins/dataLoading';
import TasqMixin from '@/lib/mixins/TasqMixin';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
// import { ALL_TASQS_LIST_ITEM, SHOW_ALERT} from '@/lib/constants';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import setpointV2Module from '@/store/modules/setpointV2Module';
import workflowModule from '@/store/modules/workflowModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import VueTagsInput from '@johmun/vue-tags-input';
import workspaceModule from '@/store/modules/workspaceModule';

import {
  ALL_TASQS_LIST_ITEM,
  SHOW_ALERT,
  TASQ_OFF_TARGET_TYPE,
} from '@/lib/constants';
import isOnline from 'is-online';
import proceduresModule from '@/store/modules/proceduresModule';
import scheduleModule from '@/store/modules/scheduleModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqSignal: () => getComponent('tasqs/TasqSignal'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    TasqFeedback: () => getComponent('tasqs/TasqFeedbackV3'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    TasqItem: () => getComponent('tasqs/TasqItem'),
    ChartIq: () => getView('ChartIQ'),
    Multiselect,
    DateRangePicker,
    VueTagsInput,
  },
})
export default class 
TasqsDesktop extends mixins(DataLoading, TasqMixin) {
  tasqOptionsOpen = false;

  chartsLoading = false;

  tag: any =  '';
    
  tags: any = [];

  activeTableID: any = '';

  activeWorkspaceID: any = '';

  showChartModal: any = false;

  autoCompleteTags: any = [];


  equipment: any = '';

  equipments: any = [];

  signalChartTime = 14;

  loadingTasqs = false;

  isOnlineApp = true;

  customSignalRange: any = { startDate: null, endDate: null }

  showComment = false;

  multiTasqType = '';

  subPredictionList: any = [];

  comment = '';

  visiblePadWellsFrom = 0

  visiblePadWellsTo = 5

  showActionDialog: boolean = false;

  showDataLoadingFeedback = false

  action: any = {
    type: '',
    title: '',
    confirmText: '',
    reason: [],
    person: '',
    comment: '',
    date: '',
    well: '',
    jobType: '',
    frequency: '',
    delayedDays: 0,
  };

  chartTimes: any[] = this.$getConst('CHART_TIMES');

//   windowWidth = window.innerWidth;

//   get isNarrowScreen() {
//      return this.windowWidth < 1550;
//    }


  get isEditing() {
	  return tasqsListModule.isEditing;
  }

  closeEditToolbar() {
	  tasqsListModule.setIsEditing(false);
	  tasqsListModule.setIsBatchResponding(false);
    tasqsListModule.setIsBatchReassign(false);
    // tasqsListModule.setActiveTasq('');
	  tasqsListModule.resetChekedTasqs();
  }

  openAddTag(){
      // @ts-ignore
    document.querySelector(".customUINew input").style.background = "transparent";
  }

  closeAddTag(){
      // @ts-ignore
    document.querySelector(".customUINew input").style.background = "#495364";
  }


  toggleChartModal(){
    this.showChartModal = !this.showChartModal
    this.chartsLoading = true
    var elements = document.querySelectorAll('.tasqs-grid')![0];
    if(this.showChartModal){
      //  @ts-ignore
    elements.style.gridTemplateColumns = "310px auto 0px";
    }else{
      //  @ts-ignore
    elements.style.gridTemplateColumns = "310px auto 470px";
    }
    this.sleep(2000).then(() =>{
      this.chartsLoading = false;
    })
  }

  get selectedCustomRange() {
    console.log(this.customSignalRange);
    if (this.customSignalRange.startDate && this.customSignalRange.endDate) {
      return true;
    }
    return false;
  }

  get dateRange() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    return {
      dateRange: { startDate, endDate },
    };
  }

  get isBatchResponding() {
	  return tasqsListModule.isBatchResponding;
  }

  batchRespond() {
	  if (this.respondEnabled) {
		  tasqsListModule.setIsBatchResponding(true);
	  }
  }

  async batchReassign() {
	  if (this.respondEnabled) {
      tasqsListModule.setIsBatchReassign(true);
      await accountModule.getReassignmentList();
		  this.prepareAction('reassign');
	  }
  }

  get adminUser(){
    return [
      'ross@tasqinc.com',
      'aneqafzl@gmail.com',
    ].includes(accountModule.user.email.toLowerCase())
  }

  async batchWait() {
	  if (this.respondEnabled) {
      tasqsListModule.setIsBatchReassign(true);
		  this.prepareAction('wait');
	  }
  }

  get getBatchEditResponseDisabledMsg() {
	  return tasqsListModule.batchResponseDisabledMsg;
  }

  get respondEnabled() {
	  return tasqsListModule.batchResponseEnabled;
  }

  get selectedEditItemsCount() {
	  return tasqsListModule.checkedTasqs.length;
  }

  padWellsSkipToEnd() {
	  this.visiblePadWellsFrom = (Math.ceil(this.activeTasq?.wells.length / 6) - 1) * 6;
	  this.visiblePadWellsTo = this.visiblePadWellsFrom + 5;
  }

  padWellsNext() {
	  if (this.visiblePadWellsTo >= this.activeTasq?.wells.length || this.visiblePadWellsFrom + 6 >= this.activeTasq?.wells.length) {
		  return;
	  }
	  this.visiblePadWellsFrom += 6;
	  this.visiblePadWellsTo += 6;
  }

  padWellsPrevious() {
	  if (this.visiblePadWellsFrom - 6 < 0) {
		  return;
	  }
	  this.visiblePadWellsFrom -= 6;
	  this.visiblePadWellsTo -= 6;
  }

  padWellsSkipToBeginning() {
    this.visiblePadWellsFrom = 0;
    this.visiblePadWellsTo = 5;
  }

  get dateRangeCustomPreset() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return {
      
      'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()), new Date(today.getFullYear(), today.getMonth(),  today.getDate())],
      'Last 3 months': [new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()), new Date(today.getFullYear(), today.getMonth(),  today.getDate())],
      'Last 6 months': [new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()), new Date(today.getFullYear(), today.getMonth(),  today.getDate())],
      'Last Year':  [new Date(today.getFullYear(), today.getMonth() - 12, today.getDate()), new Date(today.getFullYear(), today.getMonth(),  today.getDate())],
    };
  }

  get showErrorMsgTooltip() {
	  return !this.respondEnabled && tasqsListModule.checkedTasqs.length > 0;
  }

  //   getPadWellPaginationElement() {
  // 	  return '<div></div>'
  //   }

  getWells() {
    const responseData: any[] = [];
	  if (this.activeTasq != null) {
		  // @ts-ignore
		  for (let r = 0; r < this.activeTasq.wells.length; r++) {
			  // @ts-ignore
        //   if (this.activeTasq.wells[r].predictionType != "Producing") {

				  // @ts-ignore
				  responseData.push(this.activeTasq.wells[r]);
        //   }
		  }
		  return responseData;
	  }
	  return responseData;
    //   if (this.showAllWells) {
    // 	  // @ts-ignore
    // 	  return this.tasq.wells
    //   } else {

    //   }
  }

  get tasqListLevel() {
	  if (tasqsListModule.tasqListLevel.toLowerCase() == "well" || (this.activeTasq && this.activeTasq.level.toLowerCase() == "well")) {
		  return "Well"
	  }
	  return tasqsListModule.tasqListLevel;
    //   if (this.activeTasq == null) {
    // 	  return 'WELL';
    //   }
    //   return this.activeTasq?.level;
  }

  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }

  get currentWellType(): any {
	  return this.$route.query.type;
  }

  get signalDescriptions(): any {
	  return tasqSignalsModule.signalDescriptions;
  }

  get currentSignals(): any {
    if (this.chartsLoading) {
      return [];
    }
    const results: any[] = [];
    for (let x = 0; x < this.signalDescriptions.length; x++) {
      for (let y = 0; y < tasqSignalsModule.currentSignals.length; y++) {
        if (tasqSignalsModule.currentSignals[y].name == this.signalDescriptions[x]) {
          results.push(tasqSignalsModule.currentSignals[y]);
        }
      }
    }
    // signalDescriptions
    return results;
  }


    async tagsAdded(newTag){
    this.tags.push(newTag);
  
  //  newTags.push(newTag)
   await scheduleModule.addTagsToWell({node_id: this.activeTasq?.wellName, tags: this.tags})
  }

  async tagsChanged(newTag){
  //  let newTags = this.tags;
  
  //  newTags.push(newTag)
   await scheduleModule.addTagsToWell({node_id: this.activeTasq?.wellName, tags: [newTag].concat(this.tags)})
  }

   async tagsRemove(removeT){
    const index = this.tags.indexOf(removeT);
      if (index > -1) {
        this.tags.splice(index, 1);
      }
   await scheduleModule.addTagsToWell({node_id: this.activeTasq?.wellName, tags: this.tags})
  }

  getPredictionList() {
    if (this.activeTasq && this.activeTasq.predictionsList && Object.keys(this.activeTasq.predictionsList)) {
      let found = false;
      this.subPredictionList = Object.keys(this.activeTasq.predictionsList).map((data) => {
        const object:any = this.activeTasq?.predictionsList[data];
        if (data === this.activeTasq?.overriddenName) {
          found = true;
        }
        if (data === 'Off-Target RT') {
          data = 'REAL-Time';
        }
        return { name: data, predictionId: object.PredictionID, color: this.bgColorClass(data) };
      });
      if (!found) {
        this.subPredictionList.push({ name: this.activeTasq.engineerType, predictionId: this.activeTasq.id, color: this.bgColorClass(this.activeTasq.overriddenName) });
      }
    } else {
      this.subPredictionList = [];
    }
  }

  get activeTasq() {
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
		  if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
		  return tasqsListModule.activeTasq as TasqJob;
	  }

    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      return tasqsListModule.activeTasq;
    }
    if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level.toLowerCase() == 'pad') {
      return tasqsListModule.activeTasq;
    }
    return assetsModule.activeTasq;
  }

  get reassignUsers() {
     return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    })).sort((a, b) => a.text.localeCompare(b.text));
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enabledWellsMapping() {
    return assetsModule.enabledWells.map((well) => ({ text: well.text, value: well.value }));
  }

  resetTasq() {
    tasqFeedbackModule.setSystemOptionsAvailable([]);
    tasqFeedbackModule.setSymptomOptionsAvailable([]);
    tasqFeedbackModule.setCauseOptionsAvailable([]);
    tasqFeedbackModule.setActionOptionsAvailable([]);
  }

  async created() {
    this.isOnlineApp = await isOnline();

    if(this.isOnlineApp){
      workspaceModule.getWorkspaces(null)
    }
      tasqsListModule.setFromNowBack(0);
    
    const tasqUsernamesFilter: any = localStorage.getItem('TasqUsernamesFilter');

    if (tasqUsernamesFilter && JSON.parse(tasqUsernamesFilter).length) {
      tasqsListModule.setUsernamesBulkList(JSON.parse(tasqUsernamesFilter));
    } else {
      tasqsListModule.setUsernamesBulkList([accountModule.user.email.toLowerCase()]);
    }

	  const width = window.innerWidth
	|| document.documentElement.clientWidth
	|| document.body.clientWidth;
    
    tasqsListModule.getTasqListResponses();
    await accountModule.getReassignmentList();
    this.loadingTasqs = true;
    tasqsListModule.resetPagination();
    tasqsListModule.setActivePage('Tasq');
 
      // console.log(tags)
    
    const promises: any[] = [];
    promises.push(tasqsListModule.getWaitList());

    promises.push(tasqsListModule.getTasqsByPagination(ALL_TASQS_LIST_ITEM));

    Promise.all(promises).then(() => {
      this.loadingTasqs = false;
      this.stopDataLoading();
      this.fetchTasqAsync();
    }, (err) => {
      // error occurred

    });
  }

  async fetchTasqAsync() {
    assetsModule.getEnabledWells();
    if (this.$route.params.id != null && this.$route.params.id != '' && this.$route.query.type == 'id') {
      await this.fetchTasq();
    }
  }

  beforeDestroy() {
    tasqsListModule.setActiveTasq('');
    // this.$router.push({name: 'Tasqs'});
    // tasqsListModule.setActivePage('');
  }

  editTasqs() {
	  if (tasqsListModule.isEditing) {
		  this.closeEditToolbar();
	  } else {
		  tasqsListModule.setActiveTasq('');
		  tasqsListModule.setIsEditing(true);
	  }
  }

  // Mickey 10

  async updateTasqPrediction(data) {
    window.history.pushState('tasqs', 'Title', `/tasqs/${data.predictionId}?type=id`);
    this.multiTasqType = data.name;
    this.showDataLoadingFeedback = true;
    workflowModule.setV2ResponseData(null);
    tasqFeedbackModule.resetAllData();

    await tasqsListModule.getTasq({
      PredictionID: data.predictionId,
    });

    // await workflowModule.getJobResponse(data?.predictionId);
    // tasqFeedbackModule.setupPage()

    await this.fetchTasq(data.predictionId, true);
    this.showDataLoadingFeedback = false;
  }

  prepareAction(action) {
    this.showActionDialog = true;
    this.action = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      well: '',
      jobType: '',
      frequency: '',
      delayedDays: 0,
    };
    switch (action) {
      case ('reject'):
        this.action.type = action;
        this.action.title = `Are you sure you want to reject ${this.activeTasq?.wellName}?`;
        this.action.confirmText = 'Yes, reject it';
        this.$dialog.show('actionModal');
        break;
      case ('reassign'):
        this.action.type = action;
        this.action.title = `Select the user to reassign ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Reassign tasq';
        this.$dialog.show('actionModal');
        break;
      case ('wait'):
        this.action.type = action;
        this.action.title = `Please select status for ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Update';
        this.$dialog.show('actionModal');
        break;
      case ('add'):
        this.action.type = action;
        this.action.title = '';
        this.action.confirmText = 'Add tasq';
        this.action.person = { value: accountModule.user.email, text: `${accountModule.user.firstName} ${accountModule.user.lastName}` };
        this.$dialog.show('actionModal');
        break;
      default:
        break;
    }
  }

  async onActionDialogClosed(response) {
    let successText = ''!;

    this.startDataLoading();
    if (response.isOk) {
      switch (this.action.type) {
        case ('reassign'):
          successText = 'Successfully reassigned!';
          await tasqActionsModule.reassignTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('reject'):
          successText = 'Successfully Rejected!';
          await tasqActionsModule.rejectTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('wait'):
          successText = 'Successfully added to waitlist!';
          await tasqActionsModule.updateTasqStatus(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('add'):
          successText = 'Tasq added successfully!';
          await tasqActionsModule.addTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        default:
          break;
      }
    }
    this.closeEditToolbar();
    this.action = {};
    this.showActionDialog = false;
    this.sleep(2000).then(async () => {
      await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    });
    this.$router.push({ name: 'Tasqs' });
    this.stopDataLoading();
    if (successText) {
      this.$eventBus.$emit(SHOW_ALERT, successText);
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async customDateUpdated(data) {
   let fromNowBack = 0;
    let dateNow: any = new Date()
    const date1 = data.startDate;
    const date2 = data.endDate;
    if(Math.ceil(Math.abs(date2)) < Math.ceil(Math.abs(dateNow))){
      fromNowBack = Math.ceil(Math.abs(date2 - dateNow) / (1000 * 60 * 60 * 24));
    }
    console.log(fromNowBack)
    tasqsListModule.setFromNowBack(fromNowBack);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    await this.changeChartTimes(diffDays);
  }

  hidePopup(){
    if(this.showChartModal){
      this.toggleChartModal();
    }
  }

  async changeChartTimes(val) {
    if(!this.selectedCustomRange){
      tasqsListModule.setFromNowBack(0);
    }
    this.signalChartTime = val;
    tasqsListModule.setSignalChartTime(val);
    // tasqsListModule.signalChartTime = val;
    this.chartsLoading = true;

    const promises: any[] = [];
    promises.push(tasqSignalsModule.getSignalsForTasq(val));
    promises.push(tasqProductionDataChartModule.getProductionData(val));
    promises.push(tasqProductionDataChartModule.getWellHistory(val));
    promises.push(defermentLabelingModule.getChartLabelingData(this.signalChartTime));

    Promise.all(promises).then(() => {
      this.chartsLoading = false;
    }, (err) => {
      // error occurred

    });
  }

  async fetchTasq(prediction_id = null, loading_from_sub_predictions = false) {
    this.showChartModal = false;
    workflowModule.setV2ResponseData(null);
    tasqFeedbackModule.resetAllData();
   tasqsListModule.setFromNowBack(0);
    // tasqFeedbackModule.resetFeedbackValues()
    if (!loading_from_sub_predictions) {
      this.chartsLoading = true;
    }
    this.chartTimes = this.$getConst('CHART_TIMES');
    tasqProductionDataChartModule.resetProductionDataDefermentLabeling();
    if (this.signalChartTime > 45) {
      this.signalChartTime = 14;
    }
    if (prediction_id != null) {
      // @ts-ignore
      if (!loading_from_sub_predictions) {
        // @ts-ignore
        await tasqsListModule.setActiveTasq(prediction_id);
        if (tasqsListModule.activeTasq == null) {
          await tasqsListModule.getTasq({
            PredictionID: prediction_id,
          });
        }
      }
    } else if (this.$route.params.id != null && this.$route.params.id != '' && this.$route.query.type == 'id') {
      await tasqsListModule.setActiveTasq(this.$route.params.id);
	  if (tasqsListModule.activeTasq == null) {
		  await tasqsListModule.getTasq({
			  PredictionID: this.$route.params.id,
		  });
	  }
    } else {
      await assetsModule.setActiveProducingTasq(this.$route.params.id);
    }
    if (!loading_from_sub_predictions) {
      this.getPredictionList();
    }

    if (this.activeTasq && this.activeTasq?.overriddenName.toLowerCase() === 'real-time') {
      this.signalChartTime = 5;
    } else {
      this.signalChartTime = 14;
    }

    if (this.activeTasq != null && (this.currentWellType != 'producing')) {
      tasqsListModule.getTasqEventHistoryList(this.activeTasq?.workflowTasqId);
      workflowModule.getWellHistory(this.activeTasq?.wellName);
      //   setpointV2Module.getSetpointRecV2ForTasq({wellName: this.activeTasq?.wellName})
	  if (this.currentWellType != 'producing') {
		  let activeTasqID = this.activeTasq?.id;
		  if (prediction_id != null) {
			  // @ts-ignore
			  activeTasqID = prediction_id;
		  }
        workflowModule.getJobResponse(activeTasqID);
		  await workflowModule.getJobResponseV2(activeTasqID);
		  this.showDataLoadingFeedback = false;
		  if (loading_from_sub_predictions) {
			  this.chartsLoading = false;
		  }
		  if (workflowModule.v2ResponseData != null) {
			  proceduresModule.getProceduresStepsJson({
				  jsonVersion: workflowModule.v2ResponseData.jsonVersion,
			  });
		  } else {
			  proceduresModule.getProceduresStepsJson({});
		  }
	  }
    } else {
      workflowModule.getWellHistory(this.activeTasq?.wellName);
    }

      if(this.isOnlineApp){
      let wellTags =  await scheduleModule.getTagsForWell({node_id: this.activeTasq?.wellName})
      this.tags = wellTags.map(t => {
        return JSON.parse(t).toLowerCase()
      })


      let availableTags =  await scheduleModule.getTagsForWell({node_id: null})
      this.autoCompleteTags = availableTags.map(t => {
        return JSON.parse(t).toLowerCase()
      })
      }
    
    // if(this.activeTasq && this.activeTasq?.overriddenName.toLowerCase() === 'real-time'){
    //   this.signalChartTime = 5
    // }
    if (!loading_from_sub_predictions) {
      const promises: any[] = [];
      promises.push(tasqSignalsModule.getSignalsForTasq(this.signalChartTime));
      promises.push(tasqProductionDataChartModule.getProductionData(this.signalChartTime));
      // promises.push(tasqProductionDataChartModule.getWellHistory(this.signalChartTime));
      promises.push(defermentLabelingModule.getChartLabelingData(this.signalChartTime));
      Promise.all(promises).then(() => {
        tasqsListModule.setSignalChartTime(this.signalChartTime);
        if (this.activeTasq?.engineerType === TASQ_OFF_TARGET_TYPE) {
          let jobOriginExists = false;
          for (let t = 0; t < this.chartTimes.length; t++) {
            if (this.chartTimes[t].text == 'Job Origin') {
              jobOriginExists = true;
            }
          }
          if (!jobOriginExists) {
            this.chartTimes = [{
              id: 5,
              val: this.activeTasq.dateAgoValue + 30,
              text: 'Job Origin',
              additionalClass: 'chart-time--narrow',
            }, ...this.chartTimes];
          }
        }
        this.chartsLoading = false;
      }, (err) => {
        console.log('Error:');
        console.log(err);
      });
    }
  }

  @Watch('$route.params.id', {
    immediate: true,
  })
  async onTasqRouteIdChange(id: string) {
    if (id) {
      await this.fetchTasq();
      this.getPredictionList();
      this.padWellsSkipToBeginning();
    } else {
      tasqsListModule.setActiveTasq('');
    }
    this.multiTasqType = '';
  }
}
